import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, regEmail } from '../../helpers/globalvalidations.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { SelectBox } from 'devextreme-react/select-box';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '60%',
    }
};

let currentTime = sessionStorage.getItem("session_branchTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_branchTime"));

export class CustomersComponent extends Component {
    static displayName = CustomersComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            customerModalIsOpen: false,
            customerSource: [],
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            customerModal: false,
            singelData: null,
            customerTypeList:[],
            id: 0,
            customerTypeId: 0,
            customerCode: "",
            customerName:"",    
            displayName:"",
            email:"",
            webAddress:"",
            addreessid:0,
            contactid:0,
            address:"",
            address2:"",
            zipcode:"",
            contactTitle:"",
            firstName:"",
            lastName:"",
            countryCode:"",
            phoneNo:"",
            mobileNo:"",
            faxNo:"",
            userId:0,
            planId:0,
            planActivateDate:currentTime,
            portalSkinId:0,
            rateTypeId:0,
            timeZoneId:0,
            errors:{
                customerTypeId: 0,
                customerCode: "",
                customerName:"",    
                displayName:"",
                email:"",
                webAddress:"",
                address:"",
                address2:"",
                zipcode:"",
                contactTitle:"",
                firstName:"",
                lastName:"",
                phoneNo:"",
                mobileNo:"",
                faxNo:"",
            }
        }
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getCustomerType();
        await this.getCustomers();
        this.setState({
            loadPanelVisible: false
        });
    }

    getCustomerType = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = "";
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customertype/drodown', requestParams);
            const data = await response.json();
            this.setState({
                customerTypeList: data.commonDropdownList,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomerType function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getCustomers = async () => {
        try {
            var request = {};
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/' + this.state.id, requestParams);
            const data = await response.json();
            this.setState({
                customerSource: data.customer,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleCustomerModal = async (e) => {
        this.setState({
            customerModal: true,
            singelData: null
        });        
    }

    handleCustomerTypeChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            // this.setState({
            //     errors:{
            //         customerTypeId: ""
            //     }
            // });
            this.state.errors["customerTypeId"] = "";
        }

        this.setState({
            customerTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    closeCustomerModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            customerModalIsOpen: false,
            customerModal: false,
            singelData: null,
            id: 0,
            customerTypeId: 0,
            customerCode: "",
            customerName:"",    
            displayName:"",
            email:"",
            webAddress:"",
            addreessid:0,
            contactid:0,
            address:"",
            address2:"",
            zipcode:"",
            contactTitle:"",
            firstName:"",
            lastName:"",
            countryCode:"",
            phoneNo:"",
            mobileNo:"",
            faxNo:"",
            userId:0,
            planId:0,
            planActivateDate:currentTime,
            portalSkinId:0,
            rateTypeId:0,
            timeZoneId:0,
            errors:{
                customerTypeId: 0,
                customerCode: "",
                customerName:"",    
                displayName:"",
                email:"",
                webAddress:"",
                address:"",
                address2:"",
                zipcode:"",
                contactTitle:"",
                firstName:"",
                lastName:"",
                phoneNo:"",
                mobileNo:"",
                faxNo:"",
            }



        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        switch (name) {
            case 'customerCode':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ customerCode: event.target.value });
                break;
            case 'customerName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ customerName: event.target.value });
                break;
            case 'displayName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ displayName: event.target.value });
                break;
            case 'email':
                this.setState({ email: event.target.value });
                break;
            case 'firstName':
                this.setState({ firstName: event.target.value });
                break;
            case 'lastName':
                this.setState({ lastName: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        this.state.errors[name] = "";
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerTypeId = 0;
            errors.customerCode = "";
            errors.customerName = "";
            errors.displayName = "";
            errors.address = "";
            errors.email = "";
            errors.webAddress = "";
            errors.firstName = ""; 
            errors.lastName = ""; 
            errors.phoneNo = ""; 
            errors.mobileNo = ""; 

            if (this.state.customerTypeId === 0) {
                formIsValid = false;
                errors.customerTypeId = "Please enter customer type.";
            }
            if (this.state.customerCode.trim() === '') {
                formIsValid = false;
                errors.customerCode = "Please enter customer code.";
            }
            if (this.state.customerName.trim() === "") {
                formIsValid = false;
                errors.customerName = "Please select customer name.";
            }
            if (this.state.displayName.trim() === '') {
                formIsValid = false;
                errors.displayName = "Please enter display name.";
            }
            if (this.state.address.trim() === '') {
                formIsValid = false;
                errors.address = "Please enter address.";
            }
            if (this.state.zipcode.trim() === '') {
                formIsValid = false;
                errors.zipcode = "Please enter zipcode.";
            }
            if (this.state.firstName.trim() === '') {
                formIsValid = false;
                errors.firstName = "Please enter first name.";
            }
            if (this.state.lastName.trim() === '') {
                formIsValid = false;
                errors.lastName = "Please enter last name.";
            }
            if (this.state.email === null || this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
            } else if (this.state.email !== '') {
                if (!regEmail.test(this.state.email)) {
                    formIsValid = false;
                    errors.email = "Please enter valid email.";
                }
            }
            if (this.state.mobileNo === null || this.state.mobileNo.trim() === "") {
                formIsValid = false;
                errors.mobileNo = "Please enter mobile number.";
            } else if (this.state.mobileNo.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").replaceAll(".", "").length !== 10) {
                formIsValid = false;
                errors.mobileNo = "Invalid mobile number.";
            }
            if (this.state.phoneNo === null || this.state.phoneNo.trim() === "") {
                formIsValid = false;
                errors.phoneNo = "Please enter phone number.";
            } else if (this.state.phoneNo.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").replaceAll(".", "").length !== 10) {
                formIsValid = false;
                errors.phoneNo = "Invalid phone number.";
            }
            if (this.state.faxNo === null || this.state.faxNo.trim() === "") {
                formIsValid = false;
                errors.faxNo = "Please enter fax number.";
            } else if (this.state.faxNo.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").replaceAll(".", "").length !== 10) {
                formIsValid = false;
                errors.faxNo = "Invalid fax number.";
            }            
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleCustomerSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.customerid = parseInt(this.state.id);
                newObject.customer_type = parseInt(this.state.customerTypeId);
                newObject.customer_code = this.state.customerCode.trim();
                newObject.customer_name = this.state.customerName.trim();
                newObject.display_name = this.state.displayName.trim();
                newObject.email_id = this.state.email.trim();
                newObject.web_address = this.state.webAddress.trim();
                newObject.addressid = parseInt(this.state.addreessid);
                newObject.contactid = parseInt(this.state.contactid);
                newObject.address = this.state.address.trim();
                newObject.address_2 = this.state.address2.trim();
                newObject.zip_code = this.state.zipcode.trim();
                newObject.contact_title = "";
                newObject.first_name = this.state.firstName.trim();
                newObject.last_name =  this.state.lastName.trim();
                newObject.country_code = "+1";
                newObject.phone_no =  this.state.phoneNo;
                newObject.mobile_no = this.state.mobileNo;
                newObject.fax_no = this.state.faxNo;
                newObject.user_id = parseInt(0);
                newObject.plan_id = parseInt(0);
                newObject.plan_activation_date = this.state.planActivateDate;
                newObject.portal_skin_id = parseInt(0);
                newObject.rate_type_id = parseInt(0);
                newObject.timezone_id = parseInt(0);

                const requestParams = getRequestParams('POST', newObject);                
                const response = await fetch(process.env.REACT_APP_API_BASE_URL +  'master/' + this.state.id + '/customer', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    Swal.fire({
                        icon: data.isSuccess ? 'info' : 'error',
                        title: data.isSuccess ? '<small>Success</small>' : 'error',
                        text: data.outputmessage,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeCustomerModal();
                            setTimeout(() => this.getCustomers(), 500);                            
                        }
                        this.setState({ isSubmited: false })
                    });
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false });
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCustomerSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    editCustomerModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                customerModal: true,
                singelData: data,
                id: data.id,
                customerTypeId: data.customer_type,
                customerCode: data.customer_code,
                customerName: data.customer_name,
                displayName: data.display_name,
                email: data.email_id,
                webAddress: data.web_address,
                addressid: data.addreess_id,
                contactid: data.contact_id,
                address: data.addresss,
                address2: data.address_2,
                zipcode: data.zip_code,
                contactTitle: data.contact_title,
                firstName: data.first_name,
                lastName: data.last_name,
                countryCode: "+1",
                phoneNo: data.phone_no,
                mobileNo: data.mobile_no,
                faxNo: data.fax_no,
            });
        }
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editCustomerModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">Customers</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                    <button type='submit' className='btn btn-primary btn-sm' onClick={this.handleCustomerModal} title='New Customer'><span><i className="fas fa-plus"></i> New Customer</span></button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className='card card-grid'>
                            <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.customerSource}
                                    keyExpr="id"
                                    id='gridCustomerList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    width="100%"
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="customer_code" caption="Customer Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                    <Column dataField="customer_type_name" caption="Customer Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="customer_name" caption="Customer Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={280} />
                                    <Column dataField="full_name" caption="Contact" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                    <Column dataField="address" caption="Address" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                                    <Column dataField="email_id" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                    <Column dataField="mobile_no" caption="Mobile" allowSearch={true} allowFiltering={false} allowSorting={true} width={90} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="Customers" />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.customerModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeCustomerModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCustomerSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Customer" : "New Customer"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                            <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>User Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.customerTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Customer code<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="customerCode" placeholder="Enter customer code" value={this.state.customerCode}
                                            disabled={this.state.id > 0} title="Customer code" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["customerCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Customer name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="customerName" placeholder="Enter customer name" value={this.state.customerName}
                                            disabled={this.state.id > 0} title="Customer Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["customerName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Display name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="displayName" placeholder="Enter display name" value={this.state.displayName}
                                            disabled={false} title="Display Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["displayName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["displayName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Address<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="address" placeholder="Enter address" value={this.state.address}
                                            disabled={false} title="Address" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["address"].length > 0 && <span className="error invalid-feedback">{this.state.errors["address"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Address 2<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="address2" placeholder="Enter address 2" value={this.state.address2}
                                            disabled={false} title="Address 2" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["address2"].length > 0 && <span className="error invalid-feedback">{this.state.errors["address2"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label>Zipcode<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="zipcode" placeholder="Enter zipcode" value={this.state.zipcode}
                                            disabled={false} title="Zip code" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["zipcode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["zipcode"]}</span>}
                                    </div>
                                </div>                                
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="email" placeholder="e.g. user@domain.com" value={this.state.email} 
                                        title="Email" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Web Address<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="webAddress" placeholder="Enter web address" value={this.state.webAddress}
                                            disabled={false} title="Web Address" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["webAddress"].length > 0 && <span className="error invalid-feedback">{this.state.errors["webAddress"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="firstName" placeholder="e.g. John" value={this.state.firstName} 
                                        title="First Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["firstName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["firstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="lastName" placeholder="e.g. Dave" value={this.state.lastName} 
                                        title="Last Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["lastName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["lastName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Mobile No <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="mobileNo" placeholder="(000) 000-0000" title="Mobile Number" value={this.state.mobileNo} 
                                        onChange={this.handleInputChange} format="(###) ###-####" />
                                        {this.state.errors["mobileNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["mobileNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Phone No <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="phoneNo" placeholder="(000) 000-0000" title="Phone Number" value={this.state.phoneNo} 
                                        onChange={this.handleInputChange} format="(###) ###-####" />
                                        {this.state.errors["phoneNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["phoneNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Fax No <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="faxNo" placeholder="(000) 000-0000" title="Fax Number" value={this.state.faxNo} 
                                        onChange={this.handleInputChange} format="(###) ###-####" />
                                        {this.state.errors["faxNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["faxNo"]}</span>}
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleCustomerSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeCustomerModal} title="Close"><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}