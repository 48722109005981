import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import TextArea from 'devextreme-react/text-area';
import { CheckBox } from 'devextreme-react/check-box';

import NumberFormat from 'react-number-format';
import { HoursData } from '../../staticdata/hours.json';
import { MinutesData } from '../../staticdata/minutes.json';
import { PaxRateData } from '../../staticdata/paxrate.json';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { CustomerType } from '../../helpers/fixcodes.js';
import { alphanumericValidation, numberValidation, regEmail } from '../../helpers/globalvalidations.js';
import Swal from 'sweetalert2';

const position = { of: '#historydiv' };

let currentTime = sessionStorage.getItem("session_branchTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_branchTime"));
let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

// const terminalTypeData = [
//     {
//         "id": 1,
//         "name": "Domestic",
//     },
//     {
//         "id": 2,
//         "name": "International",
//     },
//     {
//         "id": 3,
//         "name": "Hangar",
//     },
//     {
//         "id": 4,
//         "name": "Military",
//     },
//     {
//         "id": 5,
//         "name": "Regional",
//     },
//     {
//         "id": 6,
//         "name": "Unknown",
//     },
// ]

export class NewGreeterBookingComponent extends Component {
    static displayName = NewGreeterBookingComponent.name;
    
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            dropdownAirline: [],
            dropdownAirport: [],
            dropdownVehicleType: [],
            dropdownHours: HoursData,
            dropdownMinutes: MinutesData,
            dropdownPaxRate: PaxRateData,
            customerId:0,
            tripNotes: "",
            airportId: 0,
            airportCode:"",
            vehicleTypeId: 0,
            vehicleTypeCode:"",
            email: "",
            mobileNumber: "",
            paxFirstName: "",
            paxLastName: "",
            tripReferenceNo: "",
            airlineId: 0,
            airlineCode:"",
            flightNumber: "",
            flightDate: formatDate(currentTime, "MM/dd/yyyy"),
            flightHour: parseInt(formatDate(currentTime, "HH")),
            flightMinute: parseInt(formatDate(currentTime, "mm")),
            pax: 0,
            passenger: [],
            passengerId: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            valueForEditableTestArea:"",
            driverInfo:"",
            dropoffAddress:"",
            showCustomer: true,
            tripData: this.props.location.state === undefined ? "" : this.props.location.state.data,
            tripId: this.props.location.state === undefined ? 0 : this.props.location.state.trip_id,
            sourceUrl: this.props.location.state === undefined ? "/booking/view" : this.props.location.state.sourceUrl,
            isVIP: false,
            errors: {
                customerId:"",
                paxFirstName: "",
                paxLastName: "",
                mobileNumber: "",
                email: "",
                airlineId: "",
                airlineCode:"",
                airportId: 0,
                airportCode:"",
                flightNumber: "",
                flightDate: "",
                flightHour: "",
                flightMinute: "",
                pax: "",
                driverInfo:"",
                dropoffAddress:"",
                vehicleTypeId: "",                
                isVIP: false,
            }
        }
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getAirports();
        await this.getVehicleType();
        await this.getAirlines();        
        await this.getCustomers(this.state.sessionUser.accountId);
        switch (parseInt(this.state.sessionUser.customerType)) {
            case parseInt(CustomerType.IndividualWeb):
            case parseInt(CustomerType.PassengerService):
            case parseInt(CustomerType.Corporate):
            case parseInt(CustomerType.CorporateContract):
                this.setState({
                    showCustomer: false,
                    showGreeter: false
                });
                break;
            default:
                this.setState({
                    showCustomer: true,
                    showGreeter: true
                });
                break;
        }

        if (this.state.tripId > 0) {
            this.assignedTripData();
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    async getAirports() {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/airport/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                dropdownAirport: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAirports function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getVehicleType() {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/vehicletype/dropdown/0', requestParams);
            const data = await response.json();            
            this.setState({
                dropdownVehicleType: data.sysVehicleTypeDropdown,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getVehicleType function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getAirlines() {
        try {
            var data = JSON.parse(sessionStorage.getItem("session_airlines"));
            if (data === null) {
                var request = {};
                const requestParams = getRequestParams("GET", request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/airline/dropdown', requestParams);
                const data = await response.json();
                this.setState({
                    dropdownAirline: data.payload,
                });
            }
            else {
                this.setState({
                    dropdownAirline: data.payload,
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAirline function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getCustomers(serviceproviderid) {
        try {
            var request = {};
            request.service_provider_id = parseInt(serviceproviderid);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                customers: data.customerDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onTextAreaValueChanged = (e) => {
        debugger;
        this.setState({
            tripNotes: e.value,
        });
    }
  
    assignedTripData = () => {
        this.setState({
            customerId: parseInt(this.state.tripData.customer_id),
            email: this.state.tripData.email,
            mobileNumber: this.state.tripData.mobile,
            paxFirstName: this.state.tripData.first_name,
            paxLastName: this.state.tripData.last_name,
            //tripReferenceNo: this.state.tripData.reference_no,
            airportCode: this.state.tripData.airport,
            airlineCode: this.state.tripData.airline_code,
            flightNumber: this.state.tripData.flight_no,
            flightDate: this.state.tripData.flight_sch_time,
            pax: parseInt(this.state.tripData.no_of_pax),
            dropoffAddress: this.state.tripData.dropoff_address,
            driverInfo: this.state.tripData.driver_info,
            vehicleTypeId:this.state.tripData.vehicle_type_id,
            flightHour:parseInt(formatDate(this.state.tripData.flight_sch_time,"HH")),
            flightMinute:parseInt(formatDate(this.state.tripData.flight_sch_time,"mm")),
            tripReferenceNo: this.state.tripData.trip_ref_no,
            isVIP: this.state.tripData.is_vip_job,
            tripNotes: this.state.tripData.pickup_notes,
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case 'paxFirstName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ paxFirstName: event.target.value });
                break;
            case 'paxLastName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ paxLastName: event.target.value });
                break;
            case 'flightNumber':
                if (numberValidation.test(event.target.value))
                    this.setState({ flightNumber: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        };
        this.state.errors[name] = "";
    }

    handleValidation = (e) => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.airportId = "";
            //errors.email = "";
            //errors.mobileNumber = "";
            errors.paxFirstName = "";
            errors.paxLastName = "";
            errors.airlineId = "";
            errors.flightNumber = "";
            errors.flightDate = "";
            errors.flightHour = "";
            errors.flightMinute = "";
            errors.pax = "";
            errors.driverInfo="";
            errors.dropoffAddress="";
            //errors.vehicleTypeId="";

            switch (parseInt(this.state.sessionUser.customerType)) {
                case parseInt(CustomerType.IndividualWeb):
                case parseInt(CustomerType.PassengerService):
                case parseInt(CustomerType.Corporate):
                case parseInt(CustomerType.CorporateContract):
                    break;
                default:
                    errors.customerId = 0;    
                    if (this.state.customerId === 0) {
                        formIsValid = false;
                        errors.customerId = "Please select customer.";
                    }
                    break;
            }
            
            if (this.state.airportCode === "") {
                formIsValid = false;
                errors.airportCode = "Please select airport.";
            }
            // if (this.state.email === null || this.state.email.trim() === '') {
            //     formIsValid = false;
            //     errors.email = "Please enter email.";
            // } else 
            if (this.state.email !== '') {
                if (!regEmail.test(this.state.email)) {
                    formIsValid = false;
                    errors.email = "Please enter valid email.";
                }
            }
            if (this.state.mobileNumber === null || this.state.mobileNumber.trim() === "") {
                formIsValid = false;
                errors.mobileNumber = "Please enter mobile.";
            } else if ((this.state.mobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").replaceAll(".", "").length < 10)
            && (this.state.mobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").replaceAll(".", "").length > 13)) {
                formIsValid = false;
                errors.mobileNumber = "Invalid mobile number.";
            }
            if (this.state.paxFirstName === "") {
                formIsValid = false;
                errors.paxFirstName = "Please enter first name.";
            }
            if (this.state.paxLastName === "") {
                formIsValid = false;
                errors.paxLastName = "Please enter last name.";
            }
            if (this.state.airlineCode === "") {
                formIsValid = false;
                errors.airlineCode = "Please select airline.";
            }
            if (this.state.flightNumber === "") {
                formIsValid = false;
                errors.flightNumber = "Please enter flight number.";
            }
            if (this.state.flightDate === "") {
                formIsValid = false;
                errors.flightDate = "Please select arrival Date.";
            }
            if (this.state.flightHour === "") {
                formIsValid = false;
                errors.flightHour = "Please select arrival Hour.";
            }
            if (this.state.flightMinute === "") {
                formIsValid = false;
                errors.flightMinute = "Please select arrival Minute.";
            }
            if (this.state.pax === 0) {
                formIsValid = false;
                errors.pax = "Please select Passenger(s).";
            }
            // if (this.state.vehicleTypeId === 0) {
            //     formIsValid = false;
            //     errors.vehicleTypeId = "Please select vehicle type.";
            // }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleAirportChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["airportId"] = "";
        }

        this.setState({
            airportId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            airportCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.code : "")            
        });
    }

    handleVehicleTypeChange = (selectedOption) => {        
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["vehicleTypeId"] = "";
        }

        this.setState({
            vehicleTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            vehicleTypeCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.code : "")            
        });
    }

    handleAirlineChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["airlineId"] = "";
        }

        this.setState({
            airlineId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            airlineCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.code : "")
        });
    }

    handleFlightHourChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["flightHour"] = "";
        }

        this.setState({
            flightHour: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleFlightMinuteChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["flightMinute"] = "";
        }

        this.setState({
            flightMinute: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleArrivalDateChange = (date) => {
        let arrivalDateValue = formatDate(date.value, "MM/dd/yyyy");
        this.setState({ flightDate: arrivalDateValue })
    }

    handlePassengerChange = (selectedOption) => {        
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["passengerId"] = "";
        }

        this.setState({
            passenger: (selectedOption.selectedItem !== null ? selectedOption.selectedItem : []),
            passengerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            pax: (selectedOption.selectedItem !== null ? parseInt(selectedOption.selectedItem.pax) : 0)
        });
    }

    handleCustomerChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["customerId"] = "";
        }

        this.setState({ 
            customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), 
        });       
    }

    hanleVipValueChanged = (args) => {
        this.setState({
          isVIP: args.value,
        });
    }

    handleClearControls = () =>{
        this.setState({        
        isSubmited: false,
            loadPanelVisible: false,
            customerId:0,
            tripNotes: "",
            airportId: 0,
            airportCode:"",
            email: "",
            mobileNumber: "",
            paxFirstName: "",
            paxLastName: "",
            airlineId: 0,
            airlineCode:"",
            flightNumber: "",
            flightDate: formatDate(startTime, "MM/dd/yyyy"),
            flightHour: "",
            flightMinute: "",
            pax: 0,
            passenger: [],
            passengerId: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            valueForEditableTestArea:"",
            driverInfo:"",
            dropoffAddress:"",
            vehicleTypeId: 0,
            vehicleTypeCode:"",
            isVIP: false,
        });
    }

    handleBack = async (e) => {
        window.location.href = this.state.sourceUrl;
    }

    handleSaveGreeteReservation = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                });

                var request = {};
                request.booking_trip_id = parseInt(this.state.tripId);
                request.user_id = parseInt(sessionStorage.getItem('session_userId'));
                request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
                switch (parseInt(this.state.sessionUser.customerType)) {
                    case parseInt(CustomerType.IndividualWeb):
                    case parseInt(CustomerType.PassengerService):
                    case parseInt(CustomerType.Corporate):
                    case parseInt(CustomerType.CorporateContract):
                        request.customer_id = parseInt(this.state.sessionUser.customerId);
                        break;
                    default:
                        request.customer_id = parseInt(this.state.customerId);
                        break;
                }                
                request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
                request.airport_code = this.state.airportCode;
                request.terminal_no = "";
                request.pax_title = "";
                request.pax_first_name = this.state.paxFirstName;
                request.pax_last_name = this.state.paxLastName;
                request.pax_mobile_no = this.state.mobileNumber;
                request.pax_email_id = this.state.email;
                request.pax_numbers = parseInt(this.state.pax);
                request.airline_code = this.state.airlineCode;
                request.flight_no = this.state.flightNumber;
                request.flight_time = formatDate(this.state.flightDate, "MM/dd/yyyy") + ' ' + this.state.flightHour + ':' + this.state.flightMinute;
                request.MeetAndGreetTime = formatDate(this.state.flightDate, "MM/dd/yyyy") + ' ' + this.state.flightHour + ':' + this.state.flightMinute;
                request.trip_notes = this.state.tripNotes;
                request.trip_ref_no = this.state.tripReferenceNo;
                request.trip_payment_mode = parseInt(0);
                request.trip_transaction_id = "";
                request.trip_greeter_charge = parseFloat(0.00);
                request.trip_driver_info = this.state.driverInfo;
                request.trip_vehicle_type = this.state.vehicleTypeId;
                request.trip_pickup_address = this.state.airlineCode;
                request.trip_dropoff_address = this.state.dropoffAddress;
                request.trip_booking_source = this.state.driverInfo;
                request.is_departure_greeting = false;
                request.is_vip = this.state.isVIP;

                const requestParams = getRequestParams("POST", request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'booking/greeter/save', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'info' : 'error',
                    title: data.isSuccess ? '<small>Booking</small>' : '<small>Oops...</small>',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        this.props.location.state = undefined;
                        if (this.state.tripId === 0) {                            
                            setTimeout(() => this.handleClearControls(), 500);
                        }
                        else{
                            window.location.href = this.state.sourceUrl;
                        }
                    } else {
                        this.setState({ isGreeterMultipleAssignedSubmited: false })
                    }
                });    

                this.setState({
                    isSubmited: false
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSaveGreeteReservation function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">{this.state.tripId > 0 ? "Edit Booking [" + this.state.tripId + "]" : "New Booking (Greeter)"}</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="rez-container">
                                    <div className="card main-rez-card">
                                    <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">USER INFORMATION</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">                                                        
                                                        {
                                                            this.state.showCustomer === true
                                                                ?
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                    <div className="form-group">
                                                                        <label>Select Customer <span className="text-danger">*</span></label>
                                                                        <SelectBox
                                                                            dataSource={this.state.customers}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select Customer"
                                                                            name="customerId"
                                                                            defaultValue={0}
                                                                            value={this.state.customerId}
                                                                            displayExpr='name'
                                                                            valueExpr='id'
                                                                            onSelectionChanged={this.handleCustomerChange}
                                                                            showClearButton={true}
                                                                            disabled={this.state.tripId > 0}
                                                                            searchEnabled={true}
                                                                            width="100%"
                                                                        />
                                                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Airport where you want service <span className="text-danger">*</span></label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownAirport.length > 0 ? this.state.dropdownAirport : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select airport"
                                                                    name="airportCode"
                                                                    defaultValue={0}
                                                                    value={this.state.airportCode}
                                                                    displayExpr='code'
                                                                    valueExpr='code'
                                                                    onSelectionChanged={this.handleAirportChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["airportCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airportCode"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Email Address</label>
                                                                <input type="text" className="form-control form-control-sm" name="email" placeholder="example@domain.com" maxLength="100" title="Email" value={this.state.email} onChange={this.handleInputChange} />
                                                                {this.state.errors["email"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>Mobile Number</label>
                                                                <NumberFormat className="form-control form-control-sm" name="mobileNumber" placeholder="0000000000000" title="Mobile Number" value={this.state.mobileNumber} onChange={this.handleInputChange} />
                                                                {this.state.errors["mobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobileNumber"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>First Name</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.paxFirstName} onChange={this.handleInputChange} name="paxFirstName" placeholder="Enter first name" />
                                                                {this.state.errors["paxFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxFirstName"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>Last Name</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.paxLastName} onChange={this.handleInputChange} name="paxLastName" placeholder="Enter last name" />
                                                                {this.state.errors["paxLastName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxLastName"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Select Vehicle Type <span className="text-danger">*</span></label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownVehicleType.length > 0 ? this.state.dropdownVehicleType : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select vehicle type"
                                                                    name="vehicleTypeId"
                                                                    defaultValue={0}
                                                                    value={this.state.vehicleTypeId}
                                                                    displayExpr='vehicle_type_code'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleVehicleTypeChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>Booking Reference No</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.tripReferenceNo} onChange={this.handleInputChange} name="tripReferenceNo" placeholder="Enter Booking Reference no" />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">FLIGHT INFORMATION</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Airline <span className="text-danger">*</span></label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownAirline.length > 0 ? this.state.dropdownAirline : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select airline"
                                                                    name="airlineCode"
                                                                    defaultValue={0}
                                                                    value={this.state.airlineCode}
                                                                    displayExpr='name'
                                                                    valueExpr='code'
                                                                    onSelectionChanged={this.handleAirlineChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["airlineCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airlineCode"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>Flight # <span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={5} value={this.state.flightNumber} onChange={this.handleInputChange} name="flightNumber" placeholder="Flight #" />
                                                                {this.state.errors["flightNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightNumber"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label htmlFor="">Arrival Date <span className="text-danger">*</span></label>
                                                                <DateBox type="date"
                                                                    className="form-control form-control-sm"
                                                                    name="flightDate"
                                                                    placeholder="mm/dd/yyyy"
                                                                    useMaskBehavior={true}
                                                                    defaultValue={this.state.flightDate}
                                                                    value={this.state.flightDate}
                                                                    onValueChanged={this.handleArrivalDateChange}
                                                                    width="80%"
                                                                    acceptCustomValue={false}
                                                                />
                                                                {this.state.errors["flightDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightDate"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label htmlFor="">Arrival Time (hours)<span className="text-danger">*</span></label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownHours.length > 0 ? this.state.dropdownHours : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Hour"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.flightHour}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleFlightHourChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["flightHour"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightHour"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label htmlFor="">Arrival Time (minutes)</label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownMinutes.length > 0 ? this.state.dropdownMinutes : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Minute"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.flightMinute}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleFlightMinuteChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["flightMinute"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightMinute"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">TRAVELER INFORMATION</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>No. of Passenger <span className="text-danger">*</span></label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownPaxRate.length > 0 ? this.state.dropdownPaxRate : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select No. of Passenger"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.pax}
                                                                    displayExpr='name'
                                                                    valueExpr='pax'
                                                                    onSelectionChanged={this.handlePassengerChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["pax"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pax"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Dropoff Address</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.dropoffAddress} onChange={this.handleInputChange} 
                                                                name="dropoffAddress" placeholder="Enter Dropoff" />
                                                                {this.state.errors["dropoffAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffAddress"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Driver Info</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.driverInfo} onChange={this.handleInputChange} name="driverInfo" placeholder="Enter driver info" />
                                                                {this.state.errors["driverInfo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driverInfo"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>VIP</label><br/>
                                                                <CheckBox value={this.state.isVIP} onValueChanged={this.hanleVipValueChanged} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Notes</label>
                                                                <TextArea
                                                                    height={70}
                                                                    // readOnly={true}
                                                                    // value={this.state.tripNotes}
                                                                    // valueChangeEvent={this.onTextAreaValueChanged}
                                                                    value={this.state.tripNotes}
                                                                    //valueChangeEvent={this.state.tripNotes}
                                                                    onValueChanged={this.onTextAreaValueChanged}
                                                                /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>&nbsp;</label><br />
                                        {/* <button type='submit' className='btn btn-primary btn-sm' onClick={this.handleSaveGreeteReservation} style={{ marginTop: 5 + 'px' }}>
                                            {this.state.isSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span><i className="fas fa-check"></i>{this.state.tripId > 0 ? " Update Reservation" : " Save Reservations"}</span>}
                                        </button>&nbsp;&nbsp; */}
                                        {
                                            this.state.tripId > 0 ?
                                                <button type='submit' className='btn btn-dark btn-sm' onClick={this.handleBack} style={{ marginTop: 5 + 'px' }}>
                                                    {<span><i className="fas fa-arrow-left"></i> Back</span>}
                                                </button> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}