import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection
}
    from 'devextreme-react/data-grid';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { UserType, StatusType, TripStatusType } from '../../helpers/fixcodes.js';
// import { DataRow1 } from '../DataRow.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
//import { Template } from 'devextreme-react/core/template';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { BreakStyle } from 'devextreme-react/chart.js';
const position = { of: '#historydiv' };

let currentTime = sessionStorage.getItem("session_branchTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_branchTime"));
let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

export class GreeterJobComponent extends Component {
    static displayName = GreeterJobComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            isGridHasRow: false,
            dispatchSource: [],
            startTime: new Date(new Date(currentTime).setDate(currentTime.getDate())),
            endTime: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            greeterJobSource: [],
            serviceGreeters: [],
            dispatchGreeterId: 0,
            filterGreeterId: 0,
            isGreeterMultipleAssignedSubmited: false,
            selectedRowKeys: [],
            searchFromDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            customers: [],
            filterCustomerId: 0,
            jobType: 0,
            errors: {
                dispatchGreeterId: "",
            }
        }
        this.toolbarItemRender = this.toolbarItemRender.bind(this);
        this.handleSearchFromDateChange = this.handleSearchFromDateChange.bind(this);
        this.handleSearchToDateChange = this.handleSearchToDateChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleUpdateJobStatus = this.handleUpdateJobStatus.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });

        await this.getGreeters();
        await this.getJobs();
        await this.getCustomers();
        this.setState({
            loadPanelVisible: false
        });
    }

    getJobs = async (job_type) => {
        try {
            var request = {};
            request.user_id = parseInt(sessionStorage.getItem('session_userId'));
            request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            request.customer_id = parseInt(this.state.filterCustomerId);
            request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
            request.from_date = formatDate(this.state.searchFromDate, "MM/dd/yyyy hh:mm");
            request.to_date = formatDate(this.state.searchToDate, "MM/dd/yyyy hh:mm");
            request.greeter_id = parseInt(sessionStorage.getItem('session_userId'));
            request.job_type = parseInt(this.state.jobType);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'greeter/jobs', requestParams);
            const data = await response.json();
            this.setState({
                greeterJobSource: data.greeterJob,
                isGridHasRow: (data.greeterJob !== null && data.greeterJob.length > 0) || false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getJobs function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getCustomers() {
        try {
            var request = {};
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                customers: data.customerDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleDispatchSearch = async () => {
        this.getJobs();
    }

    handleAssignedJobs = async () => {
        this.setState({
            jobType: 1
        });
        setTimeout(() => this.getJobs(), 500);
    }

    handleNotAssignedJobs = async () => {
        this.setState({
            jobType: 2
        });
        setTimeout(() => this.getJobs(), 500);
    }

    handleAllJobs = async () => {
        this.setState({
            jobType: 0
        });
        setTimeout(() => this.getJobs(), 500);
    }

    async handleReset() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            isGridHasRow: false,
            dispatchSource: [],
            startTime: new Date(new Date(currentTime).setDate(currentTime.getDate())),
            endTime: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            greeterJobSource: [],
            dispatchGreeterId: 0,
            filterGreeterId: 0,
            isGreeterMultipleAssignedSubmited: false,
            selectedRowKeys: [],
            searchFromDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            filterCustomerId: 0,
            errors: {
                dispatchGreeterId: "",
            }
        });
        setTimeout(() => this.getJobs(), 500);
    }

    async getGreeters() {
        try {
            var request = {};
            request.user_id = 0; //parseInt(sessionStorage.getItem('session_userId'));
            request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            request.user_type_id = parseInt(UserType.GREETER);
            request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/users/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                serviceGreeters: data.userDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getGreeters function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleGreeterChange = (selectedOption) => {
        this.setState({ dispatchGreeterId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleFilterGreeterChange = (selectedOption) => {
        this.setState({ filterGreeterId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleSearchFromDateChange(date) {
        let fromDateValue = formatDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchFromDate: fromDateValue })
    }
    handleSearchToDateChange(date) {
        let toDateValue = formatDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchToDate: toDateValue })
    }

    handleCustomerChange = (selectedOption) => {
        this.setState({ filterCustomerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    toolbarItemRender() {
        return (
            <div className="row">
                <div className=" col-lg-7 col-md-7 col-sm-7 col-xs-7 pr-0">
                    <div>
                        <SelectBox
                            dataSource={this.state.serviceGreeters.length > 0 ? this.state.serviceGreeters : []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Greeter"
                            name="dispatchGreeterId"
                            defaultValue={0}
                            value={this.state.dispatchGreeterId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleGreeterChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                            width="100%"
                        />
                    </div>
                    {this.state.errors["dispatchGreeterId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatchGreeterId"]}</span>}
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                    <button type="button" id="btnSaveAssign" className="btn btn-dark btn-sm" title="Assign Ride" onClick={this.handleMultipleJobAssign}>
                        {this.state.isGreeterMultipleAssignedSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                        {!this.state.isGreeterMultipleAssignedSubmited && <span><i className="fas fa-check"></i> Assign</span>}
                    </button>
                </div>
            </div>
        );
    }

    onToolbarPreparing = async (e) => {
        try {
            let isButtonDisable = !this.state.isGridHasRow;
            e.toolbarOptions.items.unshift(
                //     {
                //     location: 'before',
                //     template: 'multipleGreeter'
                // }, 
                {
                    location: 'after',
                    widget: 'dxButton',
                    showText: 'always',
                    options: {
                        disabled: isButtonDisable,
                        //text: 'Export to Excel',
                        icon: 'export-excel-button',//'export',
                        elementAttr: {
                            "class": "dx-datagrid-export-button"
                        },
                        onClick: function () {
                            e.component.option("export.fileName", "GreeterJobs");
                            e.component.exportToExcel(false);
                        },
                        hint: 'Export to Excel'
                    }
                },
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'refresh',
                        onClick: this.getJobs.bind(this),
                        hint: 'Refresh'
                    }
                }
            )
        } catch (e) {
            console.log(e);
        }
    }

    handleRowPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.greeter_id > 0) {
                e.rowElement.classList.add("bgGreeterAssigned");
            }
            else if (e.data.is_vip_job === true) {
                e.rowElement.classList.add("bgVipJob");
            }
        }
    }

    editTrip = (e, tripData) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/booking/greeter/new',
            state: { data: tripData, sourceUrl: "/greeterjob", trip_id: tripData.trip_id }
        });
        window.location.reload(false);
    }

    async askMultipleVehicleAssignment(blflag, tripData) {
        let isResult = false;
        let tripStatus = "";
        if (parseInt(tripData.status_id) === parseInt(StatusType.GreeterAssigned) && parseInt(tripData.trip_status_id) === parseInt(TripStatusType.Open)) {
            tripStatus = "On Location";
        }
        else if (parseInt(tripData.trip_status_id) === parseInt(TripStatusType.ChauffeuratSite)) {
            tripStatus = "On Board";
        }
        else if (parseInt(tripData.trip_status_id) === parseInt(TripStatusType.PassangerBoarded)) {
            tripStatus = "Complete";
        }
        else {
            tripStatus = "";
        }

        await Swal.fire({
            title: "<small>Are you sure?</small>",
            html: "<small>" + tripStatus !== "" ? "Do you want to update the status to [" + tripStatus + "]?" : "Do you want to update the status" + "?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#343a40',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            //allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    handleUpdateJobStatus = async (e, tripData) => {
        e.preventDefault();
        const isConfirm = await this.askMultipleVehicleAssignment(true, tripData);
        if (!isConfirm) {
            return;
        }

        let tripStatusId = 0;
        let tripStatus = "";
        if (parseInt(tripData.status_id) === parseInt(StatusType.GreeterAssigned) && parseInt(tripData.trip_status_id) === parseInt(TripStatusType.Open)) {
            tripStatusId = parseInt(TripStatusType.ChauffeuratSite);
            tripStatus = "On Location";
        }
        else if (parseInt(tripData.trip_status_id) === parseInt(TripStatusType.ChauffeuratSite)) {
            tripStatusId = parseInt(TripStatusType.PassangerBoarded);
            tripStatus = "On Board";
        }
        else if (parseInt(tripData.trip_status_id) === parseInt(TripStatusType.PassangerBoarded)) {
            tripStatusId = parseInt(TripStatusType.TripComplete);
            tripStatus = "Complete";
        }
        else {
            tripStatusId = parseInt(StatusType.GreeterAssigned);
        }

        var updateJobRequest = {};
        updateJobRequest.trip_id = tripData.trip_id;
        updateJobRequest.user_id = parseInt(sessionStorage.getItem('session_userId'));
        updateJobRequest.isapproved = false;
        updateJobRequest.isvoid = false;
        updateJobRequest.voidreason = "";
        updateJobRequest.status_time = formatDate(currentTime, "MM/dd/yyyy hh:mm");
        updateJobRequest.status_Id = parseInt(tripStatusId);

        const requestParams = getRequestParams('POST', updateJobRequest);
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'booking/job/update-status', requestParams);
        const data = await response.json();

        Swal.fire({
            icon: data.isSuccess ? 'info' : 'error',
            title: data.isSuccess ? '<small>Update Booking Status' : '<small>Oops...</small>',
            text: data.resultMessage + " to [" + tripStatus + "]",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                setTimeout(() => this.getJobs(), 500);
            } else {
                this.setState({ isGreeterMultipleAssignedSubmited: false })
            }
        });
    }

    render() {
        const renderFlightCell = (data) => {
            return <div>
                <img src={data.data.airline_icon} width="20" />&nbsp;{data.data.airline_info}
            </div>;
        }

        const renderGreeterCell = (data) => {
            return <div>
                {
                    parseInt(data.data.status_id) === parseInt(StatusType.GreeterAssigned) ?
                        <a href="#" className="fas fa-unlink" title="UnAssign Greeter" onClick={(e) => { this.unAssignGreeter(e, data.data) }}></a>
                        : null
                }
                &nbsp;{data.data.greeter_name}
            </div>;
        }

        const renderTripGridCell = (data) => {
            return <div>
                <a href="#" title={"Edit booking " + data.data.trip_id} onClick={(e) => { this.editTrip(e, data.data) }} ><span className="rideTextColor">{data.data.trip_id}</span></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGreeterGridCell = (data) => {
            return <div>
                <a target="_blank" href={"http://localhost:3000/banner/" + data.data.trip_id}><img src='https://cloud.airport-greeter.com/dist/img/ico-tablet.png' /></a>
            </div>;
        }

        const renderJobStatusCell = (data) => {
            return <div>
                {/* {data.data.status_id} - {data.data.trip_status_id} */}
                <div className='mergeData ellipsis'>
                    {
                        ((parseInt(data.data.status_id) === StatusType.GreeterAssigned) && parseInt(data.data.trip_status_id) === TripStatusType.Open) ?
                            <button type="submit" className="btn btn-dark btn-block btn-sm mr-2" data-slide="true" onClick={(e) => { this.handleUpdateJobStatus(e, data.data) }}>
                                <span title="On Location">On Location</span>
                            </button>
                            : (parseInt(data.data.trip_status_id) === TripStatusType.ChauffeuratSite) ?
                                <button type="submit" className="btn btn-dark btn-block btn-sm mr-2" data-slide="true" onClick={(e) => { this.handleUpdateJobStatus(e, data.data) }}>
                                    <span title="Board Pax">Board Pax</span>
                                </button>
                                :
                                (parseInt(data.data.trip_status_id) === TripStatusType.PassangerBoarded) ?
                                    <button type="submit" className="btn btn-dark btn-block btn-sm mr-2" data-slide="true" onClick={(e) => { this.handleUpdateJobStatus(e, data.data) }}>
                                        <span title="Complete">Complete</span>
                                    </button>
                                    : <button type="submit" className="btn btn-dark btn-block btn-sm mr-2" data-slide="true">
                                        <span title="Completed">Completed</span>
                                    </button>
                    }
                </div>
            </div>;
        }

        const renderPassengerDetailsCell = (data) => {
            return <div className='mergeData ellipsis'>
                <span className='text-muted'></span>{data.data.pax_name}
                <br />
                <span className='mr-2 dispatch-pax-mob'><span className='text-muted'>{data.data.mobile}</span></span>
                {/* <span className='dispatch-pax-count'><span className='text-dark'><i className="fas fa-users"></i></span> <span className='text-muted'></span>{data.data.no_of_pax} Pax</span> */}
            </div>;
        }

        const renderPaxCell = (data) => {
            return <div><span className='text-muted'></span>{data.data.no_of_pax} Pax</div>;
        }

        const renderPickupDetailsCell = (data) => {
            return <div className='mergeData ellipsis'>
                <span className='text-muted'></span>{data.data.pickup_address}
                <br />
                <span className='text-muted'></span>{formatDate(data.data.pickup_time, "MM/dd/yyyy hh:mm")}
            </div>;
        }

        const renderDriverDetailsCell = (data) => {
            return <div className='mergeData ellipsis text-truncate'>
                <span className='text-muted'></span>{data.data.driver_info.split(' ')[0]}
                <br />
                <span className='text-muted'>{data.data.driver_info.substring(data.data.driver_info.split(' ')[0].length, data.data.driver_info.length)}</span>
            </div>;
        }

        return (
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <h1 className="m-0 text-light">Agent Jobs</h1>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 justify-content-end">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div className="">
                                                <DateBox type="date"
                                                    className="form-control form-control-sm"
                                                    name="fromDate"
                                                    placeholder="mm/dd/yyyy"
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.searchFromDate}
                                                    value={this.state.searchFromDate}
                                                    onValueChanged={this.handleSearchFromDateChange}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div className="">
                                                <DateBox type="date"
                                                    className="form-control form-control-sm"
                                                    name="toDate"
                                                    placeholder="mm/dd/yyyy"
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.searchToDate}
                                                    value={this.state.searchToDate}
                                                    onValueChanged={this.handleSearchToDateChange}
                                                    width="100%"
                                                    acceptCustomValue={false} />
                                            </div>
                                        </div>
                                        <div className="mb-0">
                                            <button type="submit" className="btn btn-default btn-sm" onClick={this.handleDispatchSearch} data-slide="true">
                                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In-process"></i> In-process</span>}
                                                {!this.state.isSubmited && <span title="Search"><i className="fas fa-search" title="Search"></i></span>}
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-default btn-sm mr-2" onClick={this.handleReset} data-slide="true">
                                                <span title="Reset"><i className="fas fa-eraser" title="Reset"></i></span>
                                            </button>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">

                        </div>
                        <div className="content">
                            <div className='card card-grid'>
                                <div className='card-body'>
                                    <DataGrid
                                        ref={(ref) => this.dataGrid = ref}
                                        dataSource={this.state.greeterJobSource}
                                        keyExpr="trip_id"
                                        id="dispatch-greeter-container"
                                        name="gridGreeterContainer"
                                        width="100%"
                                        columnsAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        onRowPrepared={this.handleRowPrepared}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        onSelectionChanged={this.handleMultipleRideSelectionChanged}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        {/* <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"none"}
                                        /> */}
                                        <Column dataField="trip_id" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={40} alignment="center" cellRender={renderGreeterGridCell} />
                                        <Column dataField="trip_id" caption="Trip#" allowSearch={true} allowFiltering={false} allowSorting={true} width={70} alignment="left" />
                                        <Column dataField="airline_info" caption="Flight" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} cellRender={renderFlightCell} />
                                        {/* <Column dataField="pickup_address" caption="Airport" allowSearch={true} allowFiltering={false} allowSorting={true} width={60} /> */}
                                        <Column dataField="pax_name" caption="Passenger Details" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderPassengerDetailsCell} />
                                        <Column dataField="pickup_address" caption="Pickup Details" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} cellRender={renderPickupDetailsCell} />
                                        <Column dataField="no_of_pax" caption="Pax#" allowSearch={true} allowFiltering={false} allowSorting={true} width={50} alignment="left" cellRender={renderPaxCell} />
                                        {/* <Column dataField="pickup_time" caption="Pick-up Date Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} dataType="datetime" format="MM/dd/yyyy HH:mm" /> */}                                        
                                        {/* <Column dataField="pax_name" caption="Pax Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} /> */}
                                        
                                        {/* <Column dataField="mobile" caption="Mobile#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} alignment="left" /> */}
                                        <Column dataField="customer_name" caption="Greeter Company" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="driver_info" caption="Driver" allowSearch={true} allowFiltering={false} allowSorting={true} width={170} cellRender={renderDriverDetailsCell} />
                                        <Column dataField="trip_id" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={45} alignment="center" cellRender={renderJobStatusCell} />
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        {/* <Template name="multipleGreeter" render={this.toolbarItemRender} /> */}
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} fileName="GreeterJobs" />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    };
}