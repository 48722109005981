import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CustomerType } from '../helpers/fixcodes.js';
import './WebMenu.css';

export class TopBarComponent extends Component {
    static displayName = TopBarComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            displayName: TopBarComponent.name,
            pageName: sessionStorage.getItem("PageName") === null ? "Dashboard" : sessionStorage.getItem("PageName"),
            wsurl: sessionStorage.getItem("session_socketApiUrl"),
            notificationModalIsOpen: false,
            notificationMessage: [],
            cssName: '/dist/css/skins/skin-white.css',
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            menuAccess: true,
        };
    }

    componentDidMount() {
        switch (parseInt(this.state.sessionUser.customerType)) {
            case parseInt(CustomerType.IndividualWeb):
            case parseInt(CustomerType.PassengerService):
            case parseInt(CustomerType.Corporate):
            case parseInt(CustomerType.CorporateContract):
                this.setState({
                    menuAccess: false
                })
                break;
            default:
                this.setState({
                    menuAccess: true
                })
                break;
        }
    }

    closeNotificationModal() {
        this.setState({
            notificationModalIsOpen: false,
            notificationMessage: [],
        });
    }

    handleLogout = async (e) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = "/";
    }

    toggleSidebar = (e) => {
        e.preventDefault();
        if (document.getElementsByTagName("body")[0].className === "sidebar-mini") {
            document.getElementsByTagName("body")[0].classList.remove("sidebar-mini")
        } else {
            document.getElementsByTagName("body")[0].classList.add("sidebar-mini")
        }
    }

    render() {
        return (
            <header>
                <link rel="stylesheet" type="text/css" href={this.state.cssName} />
                <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                    <div className="container">
                        <a href="#" className="navbar-brand">
                            <img src="../../dist/img/logo-airporter-greeter-com.png" style={{ width: 120 + 'px' }} alt="GTS" />
                        </a>
                        <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                            {/* <ul className="navbar-nav">
                                <li className="nav-item active"><a className="nav-link" href="/booking/greeter/new">New Booking (Greeter) </a></li>
                            </ul> */}
                            {
                                this.state.menuAccess === true ?
                                    <div style={{ visibility: 'hidden' }}>
                                        <ul className='navbar-nav'>
                                            <NavItem>
                                                <NavLink tag={Link} to="/dashboard" title="Dashboard">
                                                    <i className=""></i><span>Dashboard</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="dropdown">
                                                <a href="/" className="nav-link has-dropdown dropdown-toggle" title="Booking" data-toggle="dropdown">
                                                    <i className=""></i><span>Booking</span>
                                                </a>
                                                <ul className="dropdown-menu border-0 shadow">
                                                    <NavItem key="1">
                                                        <NavLink tag={Link} to="/booking/greeter/new" title="New Booking (Greeter)">
                                                            <i className=""></i><span>New Booking (Greeter)</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem key="2">
                                                        <NavLink tag={Link} to="/booking/view" title="View Booking">
                                                            <i className=""></i><span>View Booking</span>
                                                        </NavLink>

                                                    </NavItem>
                                                    <NavItem key="3">
                                                        <NavLink tag={Link} to="/upload/GreeterReservations" title="Upload Greeter Booking">
                                                            <i className=""></i><span>Upload Greeter Booking</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </NavItem>
                                            <NavItem className="dropdown">
                                                <a href="/" className="nav-link has-dropdown dropdown-toggle" title="Dispatch" data-toggle="dropdown">
                                                    <i className=""></i><span>Dispatch</span>
                                                </a>
                                                <ul className="dropdown-menu border-0 shadow">
                                                    <NavItem key="4">
                                                        <NavLink tag={Link} to="/greeter" title="Greeter Dispatch">
                                                            <i className=""></i><span>Greeter Dispatch</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </NavItem>
                                            <NavItem className="dropdown">
                                                <a href="/" className="nav-link has-dropdown dropdown-toggle" title="Masters" data-toggle="dropdown">
                                                    <i className=""></i><span>Masters</span>
                                                </a>
                                                <ul className="dropdown-menu border-0 shadow">
                                                    <NavItem>
                                                        <NavLink tag={Link} to="/customers" title="Customers">
                                                            <i className=""></i><span>Customers</span>
                                                        </NavLink>
                                                        <NavLink tag={Link} to="/users" title="Users">
                                                            <i className=""></i><span>Users</span>
                                                        </NavLink>
                                                        <NavLink tag={Link} to="/customerusers" title="Greeter Company">
                                                            <i className=""></i><span>Greeter Company</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </NavItem>
                                        </ul>
                                    </div>
                                    :
                                    <ul className="navbar-nav">
                                        <li>
                                            <NavLink tag={Link} to="/booking/view" title="View Booking">
                                                <i className=""></i><span>View Booking</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                            }
                            {/* {
                                this.state.menuAccess === true ?
                                    <ul className="navbar-nav">
                                        <li className="nav-item"><a className="nav-link" onClick={(e) => { this.menuClick(e, "Greeter") }} href="/dashboard">Dashboard</a></li>
                                        <li className="nav-item dropdown"><a id="dropdownReservation" href="/#" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Booking</a>
                                            <ul aria-labelledby="dropdownReservation" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                                <li className="nav-item"><a className="dropdown-item nav-link" href="/booking/greeter/new" onClick={(e) => { this.menuClick(e, "New") }}>
                                                    New Booking (Greeter)</a></li>
                                                <li className="nav-item"><a className="dropdown-item nav-link" href="/booking/view" onClick={(e) => { this.menuClick(e, "Upload") }}>
                                                    View Booking</a></li>
                                                <li className="nav-item"> <a className="dropdown-item nav-link" href="/upload/GreeterReservations" onClick={(e) => { this.menuClick(e, "Upload") }}>
                                                    Upload Greeter Booking</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown"><a id="dropdownReservation" href="/" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Dispatch</a>
                                            <ul aria-labelledby="dropdownReservation" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                                <li className="nav-item"> <a className="dropdown-item nav-link" href="/greeter" onClick={(e) => { this.menuClick(e, "Greeter") }}>
                                                    Greeter Dispatch</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown"><a id="dropdownReservation" href="/#" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Masters</a>
                                            <ul aria-labelledby="dropdownReservation" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                                <li className="nav-item"><a className="dropdown-item nav-link" href="/customers" onClick={(e) => { this.menuClick(e, "Customers") }}>
                                                    Customers</a></li>
                                                <li className="nav-item"> <a className="dropdown-item nav-link" href="/users" onClick={(e) => { this.menuClick(e, "Users") }}>
                                                    Users</a></li>
                                                <li className="nav-item"> <a className="dropdown-item nav-link" href="/customerusers" onClick={(e) => { this.menuClick(e, "Customer Users") }}>
                                                    Greeter Company</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    :
                                    <ul className="navbar-nav">
                                        <li className="nav-item"><a className="nav-link" href="/booking/view">Booking</a></li>
                                    </ul>
                            } */}
                            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                                <li className="nav-item dropdown">
                                    <a className="nav-link" data-toggle="dropdown" href="#">
                                        <i className="fas fa-user-circle"></i>&nbsp;&nbsp;
                                        {sessionStorage.getItem('session_fullName')}&nbsp;&nbsp;<i className="fas fa-arrow-down"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right user-menu">
                                        <a href="/#" className="dropdown-item"></a>
                                        <div className="media">
                                            <img alt="User Profile" src="../../dist/img/user1-128x128.jpg" className="img-size-50 mr-3 img-circle" />
                                            <div className="media-body">
                                                <p><b>{sessionStorage.getItem('session_accountName')}</b></p>
                                            </div>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <span className="dropdown-item">
                                            <a href="/#" className="text-info" onClick={this.handleLogout}>Sign out</a>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}