import React, { Component } from 'react';
import { getRequestParams } from '../../src/helpers/globalfunctions.js';
import { CustomerType } from '../helpers/fixcodes.js';
import jsSHA from "jssha"; // npm install jssha

export class LoginComponent extends Component {
    static displayName = LoginComponent.name;

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);

        this.state = {
            userName: '', password: '', isSubmited: false, message: '',
            ipAddress: '127.0.0.1', latitude: 0, longitude: 0,
            errors: {
                userName: '',
                password: ''
            },
            userToken: this.props.location.search !== "" ? parsed.userguid : "",
            //menuAccess: true,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearControl = this.clearControl.bind(this);
        this.calcHash = this.calcHash.bind(this);
        this.uuidv4 = this.uuidv4.bind(this);
        //console.log(process.env.REACT_APP_API_BASE_URL);
    }

    clearControl() {
        this.setState({
            userName: '', password: '', isSubmited: false, message: '',
            errors: {
                userName: '',
                password: ''
            }
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.userName = "";
            errors.password = "";
            this.setState({ message: '' });

            if (this.state.userName === '') {
                formIsValid = false;
                errors.userName = "Please enter username.";
            }

            if (this.state.password === '') {
                formIsValid = false;
                errors.password = "Please enter password.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    calcHash(value, securityKey) {
        try {
            let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj.update(value);
            let saltText = shaObj.getHash("HEX");

            let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj2.update(saltText + securityKey);

            return shaObj2.getHash("HEX");
        } catch (err) {
            console.log("Error in " + this.displayName + ".calcHash function", err);
        }
    }

    uuidv4() {
        return '00-0-4-1-000'.replace(/[^-]/g,
            s => ((Math.random() + ~~s) * 0x10000 >> s).toString(16).padStart(4, '0')
        );
    }

    async getRedirectUser() {
        var request = {};
        request.Token = this.state.userToken;
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'RedirectUser', requestParams);
        const data = await response.json();
        if (data.payload != null) {
            this.setState({ userName: data.payload });
            this.login();
        }
    }

    componentDidMount() {
        if (this.state.userToken !== "") {
            this.getRedirectUser();
        }

        // switch(parseInt(this.state.sessionUser.customerType))
        // {
        //     case parseInt(CustomerType.IndividualWeb):
        //     case parseInt(CustomerType.PassengerService):
        //     case parseInt(CustomerType.Corporate):
        //     case parseInt(CustomerType.CorporateContract):
        //         this.setState({
        //             menuAccess : false
        //         })
        //         break;
        //     default:
        //         this.setState({
        //             menuAccess : true
        //         })
        //         break;
        // }

        navigator.geolocation.getCurrentPosition(position => {
            this.setState({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
        })
    }

    async login() {
        try {
            let passwordHash = "";
            var passwordTokenRequest = {};
            passwordTokenRequest.UserName = this.state.userName;
            const passwordTokenRequestParams = getRequestParams('POST', passwordTokenRequest);
            if (this.state.userToken === "") {                
                const passwordTokenResponse = await fetch(process.env.REACT_APP_API_BASE_URL + 'verification/password-generate-token/', passwordTokenRequestParams);
                const passwordToken = await passwordTokenResponse.json();                
                if (passwordToken.isSuccess) {
                    passwordHash = this.calcHash(this.state.password, passwordToken.token);
                } else {
                    this.setState({ isSubmited: false, message: passwordToken.resultMessage });
                    return;
                }
            }

            const requestParams = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: this.state.userName, userpasscode: passwordHash })
            }

            //console.log("Service URL: " + process.env.REACT_APP_API_BASE_URL);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'verification/validate-user/', requestParams);
            await this.getAirlines();
            const data = await response.json();
            if (data.isSuccess) {
                sessionStorage.setItem("session_user", JSON.stringify(data.userContext));
                sessionStorage.setItem("session_userId", data.userContext.userId);
                sessionStorage.setItem("session_accountId", data.userContext.accountId);
                sessionStorage.setItem("session_accountName", data.userContext.accountName);
                sessionStorage.setItem("session_userName", data.userContext.userName);
                sessionStorage.setItem("session_fullName", data.userContext.fullName);
                sessionStorage.setItem("session_branchId", data.userContext.branchId);
                sessionStorage.setItem("session_branchName", data.userContext.branchName);
                sessionStorage.setItem("session_customerType", data.userContext.customerType);
                sessionStorage.setItem("session_customerId", data.userContext.customerId);
                debugger;
                sessionStorage.setItem("session_branchTime", data.userContext.branch_current_time);
                sessionStorage.setItem("session_loggedUser", true); 

                switch (parseInt(data.userContext.customerType)) {
                    case parseInt(CustomerType.IndividualWeb):
                    case parseInt(CustomerType.PassengerService):
                    case parseInt(CustomerType.Corporate):
                    case parseInt(CustomerType.CorporateContract):
                        this.setState({
                            menuAccess: false
                        })
                        break;
                    default:
                        this.setState({
                            menuAccess: true
                        })
                        break;
                }
                if (this.state.menuAccess){
                    window.location.href = "/greeterjob";
                }
                else {
                    window.location.href = "/booking/view";
                }                
            }
            else {
                this.setState({ isSubmited: false, message: data.userContext != null ? data.message : "Invalid username/password" });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".login function", err);
            this.setState({ isSubmited: false, message: "Invalis Username/Password" });
        }
    }

    async getAirlines() {
        try {            
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/airline/dropdown', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_airlines", data != null ? JSON.stringify(data) : []);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAirline function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async handleSubmit(e) {
        try {
            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                this.login();
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSubmit function", err);
            this.setState({ isSubmited: false, message: "Oops something went worng !!!" });
        }
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="card card-login">
                        <div className="card-body">
                            <div className="login-logo mb-0"><img src="../../dist/img/logo-airporter-greeter-com.png" alt="Airport Greeter" width="160px" /></div>
                            <span className="login-box-msg">&nbsp;</span>
                            <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="userName" placeholder="Username" value={this.state.userName} onChange={this.handleInputChange} />                                    
                                    {this.state.errors["userName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userName"]}</span>}
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} />
                                    {this.state.errors["password"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["password"]}</span>}
                                    {this.state.message.length > 0 && <span className='error invalid-feedback'>{this.state.message}</span>}
                                </div>
                                <div className="row form-group">
                                    <div className="col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember" />
                                            <label className="form-check-label" htmlFor="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>                                    
                                    <div className="col-6 text-right">
                                        <button type="submit" className="btn btn-primary btn-sm" onClick={this.handleSubmit} title="Login" >
                                            {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i> Login</span>}
                                            {!this.state.isSubmited && <span title="Login"><i className="fas fa-chevron-right"></i> Login</span>}
                                        </button>&nbsp;
                                        {/* <button type="button" className="btn btn-danger btn-sm" onClick={this.clearControl} title="Clear"><i className="fa fa-eraser"></i> Clear</button> */}
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div><NavLink tag={Link} to="/forgotpassword" title="I forgot my password" className="text-sm p-0 mb-0">Forgot password</NavLink></div>
                                    </div>                                   
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}