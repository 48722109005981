import React, { Component, Fragment } from 'react';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { ShowGreeterBannerComponent } from "./ShowGreeterBannerComponent";
import { BlnkGreeterBannerComponent } from "./BlnkGreeterBannerComponent";

export class BannerComponent extends Component {

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = this.props.location.pathname;
        this.state = {
            paxName: parsed.replace("/banner/", ""),
            tripId: parsed.replace("/banner/", "") != undefined ? parseInt(parsed.replace("/banner/", "")) : 0,
            bookingSource: [],
            showHideDefaultTemplate: false,
            showHideBlnkTemplate: false,
        };
    }

    async componentDidMount() {
        await this.getSingleBooking();
    }

    getSingleBooking = async () => {
        try {
            debugger;
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'booking/singleBooking/'
                + parseInt(this.state.tripId), requestParams);
            const data = await response.json();
            this.setState({
                bookingSource: data.singleBooking != null ? data.singleBooking : [],
            });

            setTimeout(() => this.setGreeterTemplate(), 500);

        } catch (err) {
            console.log("Error in " + this.displayName + ".getSingleBooking function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    setGreeterTemplate = () => {
        if (this.state.bookingSource != null) {
            switch (this.state.bookingSource.customer_code) {
                case "BLNK":
                    this.setState({ showHideBlnkTemplate: !this.state.showHideBlnkTemplate });
                    break;
                default:
                    this.setState({ showHideDefaultTemplate: !this.state.showHideDefaultTemplate });
                    break;
            }
        }
        else {
            this.setState({ showHideDefaultTemplate: !this.state.showHideDefaultTemplate });
        }
    }

    render() {
        const showHideBlnkTemplate = this.state.showHideBlnkTemplate;
        const showHideDefaultTemplate = this.state.showHideDefaultTemplate;
        return (
            <Fragment>
                {(showHideDefaultTemplate && !this.state.bookingSource.allow_mg_template) && <ShowGreeterBannerComponent bookingSource={this.state.bookingSource} />}
                {(showHideBlnkTemplate && this.state.bookingSource.allow_mg_template) && <BlnkGreeterBannerComponent bookingSource={this.state.bookingSource} />}
            </Fragment>
        );
    }
}